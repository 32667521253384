import * as React from "react"
import { Fragment } from 'react'
import { Link } from "gatsby"
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay  } from 'swiper/core'
import { Fade } from "react-awesome-reveal"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Helmet } from "react-helmet"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faStar } from '@fortawesome/free-solid-svg-icons'

import 'swiper/swiper.scss'

SwiperCore.use([Autoplay])

const navigation = [
	{ name: 'Beranda', href: '/#home' },
	{ name: 'Layanan', href: '/#layanan' },
	{ name: 'Testimoni', href: '/#testimoni' },
	{ name: 'Produk', href: '/#produk' },
	{ name: 'Hubungi', href: '/#hubungi' },
  ]

const IndexPage = ({ data }) => {

	function handleClickWhatsapp() {
		window.gtag("event", "conversion", { send_to: ["AW-312158706/T8X2CLaZ7PECEPLT7JQB"]})
	}
	function handleClickCall() {
		window.gtag("event", "conversion", { send_to: ["AW-312158706/AOH6CPu9l_ICEPLT7JQB"]})
	}

	return (
		<>
			<Helmet>
					<title>AkiMarket.id - Anda Butuh Aki Mobil? Hubungi AkiMarket!</title>
					<link rel="icon" type="image/png" href={'../images/logo.png'} sizes="16x16" />
					<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
			</Helmet>
			<div id='home' className="w-full bg-white overflow-hidden">
				<div className="">
					<div className="z-30 bg-white">

					<Popover>
						{({ open }) => (
						<>
							<div className="flex flex-row justify-between py-6 border-b px-4 sm:px-6 lg:px-8">
								<div>
									<Link to="/">
										<span className="sr-only">AkiMarket</span>
										<StaticImage src="../images/logo.png" alt="logo" loading='{ eager }'  height={64} />
									</Link>
								</div>
								<nav
									className="flex items-center justify-between lg:justify-start"
									aria-label="Global"
								>
									<div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
									<div className="flex items-center justify-between w-full md:w-auto">
										<div className="-mr-2 flex items-center md:hidden">
										<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-main hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
											<span className="sr-only">Open main menu</span>
											<MenuIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
										</div>
									</div>
									</div>
									<div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
									{navigation.map((item) => (
										<AnchorLink key={item.name} to={item.href} className="font-medium text-main hover:text-main">
										{item.name}
										</AnchorLink>
									))}
									</div>
								</nav>
							</div>

							<Transition
							show={open}
							as={Fragment}
							enter="duration-150 ease-out"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="duration-100 ease-in"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
							>
								<Popover.Panel
									focus
									static
									className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20"
								>
									<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
									<div className="px-5 pt-4 flex items-center justify-between">
										<div>
											<StaticImage src="../images/logo.png" alt="logo" loading='{ eager }'  height={48} />
										</div>
										<div className="-mr-2">
										<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-main hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
											<span className="sr-only">Close main menu</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
										</div>
									</div>
									<div className="px-2 pt-2 pb-3 space-y-1">
										{navigation.map((item) => (
										<AnchorLink
											key={item.name}
											to={item.href}
											className="block px-3 py-2 rounded-md text-base font-medium text-main hover:text-gray-900 hover:bg-gray-50"
										>
											{item.name}
										</AnchorLink>
										))}
									</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
						)}
					</Popover>
					</div>
				</div>
				<div className='bg-gray-100'>
					<div className='container mx-auto px-8'>
						<div className='flex flex-col md:flex-row md:grid md:grid-cols-2 pt-8'>
							<div className='flex items-center justify-center mx-auto order-1 mt-12 md:mt-0 mb-8 md:mb-0 sm:w-4/6'>
								<div className='flex flex-col items-center'>
									<h1 className='text-2xl md:text-4xl font-semibold text-center'>Anda Butuh Aki Mobil ?<br/>Hubungi AkiMarket !</h1>
									<div className='whatsapp-button m-2 mt-8'>
										<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281384758113&text=Halo%2C akimarket.id" target="_blank" rel="noreferrer" className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-green-600 hover:bg-green-500 text-white font-bold py-3 px-4 rounded inline-flex items-center shadow-md w-48">
											<FontAwesomeIcon className='w-5 h-5 mr-2' icon={faWhatsapp} />
											<span>Whatsapp Kami</span>
										</OutboundLink>
									</div>
									<div className='phone-button m-2'>
										<OutboundLink onClick={handleClickCall} href='tel:+6281384758113' className="transition-color duration-1000 ease-in-out motion-reduce:transition-none motion-reduce:transform-none bg-main text-white font-bold py-3 px-4 rounded inline-flex items-center shadow-md w-48">
										<FontAwesomeIcon className='w-4 h-4 mr-2' icon={faPhone} rotation={90}/>
											<span>Telepon Kami</span>
										</OutboundLink>
									</div>
								</div>
							</div>
							<div className='order-2 z-0'>
								<StaticImage src="../images/blob.png" alt="blob"/>
							</div>
						</div>
						
						<div className='flex flex-row py-10'>
							<div className='flex justify-center'>
								<p className='text-md md:text-lg text-center'><strong>Akimarket</strong> merupakan solusi bagi permasalahan aki mobil Anda. Memberikan layanan GRATIS jasa ANTAR dan PASANG aki mobil Anda. <strong>Akimarket</strong> memiliki beberapa cabang yang tersebar di area JABODETABEK, sehingga dapat memberikan layanan antar cepat ke lokasi Anda. Belanja aki tanpa ribet? Yaa di <strong>Akimarket</strong> pastinya! Nikmati berbagai promo menarik dari kami.</p>
							</div>
						</div>
					</div>
				</div>
				<div id='layanan' className='w-full flex flex-col bg-main pb-6'>
					<div className='container mx-auto'>
						<div className='flex justify-center my-10'>
							<h2 className='text-2xl md:text-3xl font-bold text-center text-white'>Layanan Kami</h2>
						</div>
						<div className='flex flex-col lg:flex-row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
							<Fade className='flex' triggerOnce='true'>
								<div className='bg-white card flex flex-col flex-1 shadow-lg p-6 m-4 rounded-lg'>
									<div className='image mx-auto'>
										<StaticImage src="../images/fast-delivery.png" alt="delivery" width={128} height={128} loading='{ lazy }' placeholder="blurred"/>
									</div>
									<div className='service-header mb-2'>
										<h3 className='font-semibold text-center'>Respon Cepat dan Antar Cepat</h3>
									</div>
									<div className='service-desc text-center'>
										<p><span className='font-semibold'>Akimarket</span> mengutamakan layanan extra cepat untuk memenuhi kebutuhan aki mobil Anda.</p>
									</div>
								</div>
							</Fade>
							<Fade className='flex' triggerOnce='true'>
								<div className='bg-white card flex flex-col flex-1 shadow-lg p-6 m-4 rounded-lg'>
									<div className='image mx-auto'>
										<StaticImage src="../images/gratis-antar.png" alt="delivery" width={128} height={128} loading='{ lazy }' placeholder="blurred"/>
									</div>
									<div className='service-header mb-2'>
										<h3 className='font-semibold text-center'>Gratis Antar dan Pasang</h3>
									</div>
									<div className='service-desc text-center'>
										<p><span className='font-semibold'>Akimarket</span> juga memberikan layanan GRATIS jasa pengantaran dan pemasangan aki mobil Anda.</p>
									</div>
								</div>
							</Fade>
							<Fade className='flex' triggerOnce='true'>
								<div className='bg-white card flex flex-col flex-1 shadow-lg p-6 m-4 rounded-lg'>
									<div className='image mx-auto'>
										<StaticImage src="../images/garansi.png" alt="delivery" width={128} height={128} loading='{ lazy }' placeholder="blurred"/>
									</div>
									<div className='service-header mb-2'>
										<h3 className='font-semibold text-center'>Original dan Bergaransi Resmi</h3>
									</div>
									<div className='service-desc text-center'>
										<p><span className='font-semibold'>Akimarket</span> hanya menjual aki yang berkualitas dan memiliki garansi resmi hingga 1 tahun.</p>
									</div>
								</div>
							</Fade>
							<Fade className='flex' triggerOnce='true'>
								<div className='bg-white card flex flex-col flex-1 shadow-lg p-6 m-4 rounded-lg'>
									<div className='image mx-auto'>
										<StaticImage src="../images/trade-in.png" alt="delivery" width={128} height={128} loading='{ lazy }' placeholder="blurred"/>
									</div>
									<div className='service-header mb-2'>
										<h3 className='font-semibold text-center'>Diskon</h3>
									</div>
									<div className='service-desc text-center'>
										<p><span className='font-semibold'>Akimarket</span> memberikan diskon untuk pembelian secara tukar tambah. Kami bayar aki bekas dengan harga terbaik.</p>
									</div>
								</div>
							</Fade>
						</div>
					</div>
				</div>
					
				<div className='bg-gray-100 py-8'>
					<div className='container mx-auto text-center px-8'>
						<div id='testimoni' className='flex flex-col my-10'>
							<div className='flex justify-center'>
								<h2 className='text-2xl md:text-3xl font-bold text-center'>Apa Kata Mereka</h2>
							</div>
						</div>
						<Swiper
						slidesPerView={1}
						centeredSlides={true} 
						autoplay={{
							"delay": 4000,
							"disableOnInteraction": false
						}} 
						>
							<SwiperSlide>
								<div className='flex flex-col w-full lg:w-3/6 mx-auto bg-white mt-12 px-6 pb-10 rounded shadow-sm'>
									<div className="absolute">
										<StaticImage height={128} width={128} src="../images/testimoni-1.png" alt="testimoni" className='w-24 m-auto left-6 -top-12 rounded-full z-10' loading='{ eager }'/>
									</div>
									<div className="rating text-left mt-16 ml-5 text-yellow-300">
										<FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
									</div>
									<p className='mt-6 mx-5 text-left'>Awalnya ragu untuk order aki secara online. Setelah dicoba ternyata beneran langsung diantar dan dipasangkan. Cepat dan tanggap lagi. Pokoknya keren deh <strong>Akimarket</strong> , semoga makin sukses!</p>
									<p className="mt-3 mx-5 text-left">- <b>Andi</b></p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-full lg:w-3/6 mx-auto bg-white mt-12 px-6 pb-10 rounded shadow-sm'>
									<div className="absolute">
										<StaticImage height={128} width={128} src="../images/testimoni-2.png" alt="testimoni" className='w-24 m-auto left-6 -top-12 rounded-full z-10' loading='{ eager }'/>
									</div>
									<div className="rating text-left mt-16 ml-5 text-yellow-300">
										<FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
									</div>
									<p className='mt-6 mx-5 text-left'>Dimasa seperti ini, emang sangat dibutuhkan layanan seperti ini. Tinggal telpon, aki langsung diantar dan dipasangkan, sangat sangat simple. Thank you, <strong>Akimarket</strong>.</p>
									<p className="mt-3 mx-5 text-left">- <b>Wahyu</b></p>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='flex flex-col w-full lg:w-3/6 mx-auto bg-white mt-12 px-6 pb-10 rounded shadow-sm'>
									<div className="absolute">
										<StaticImage height={128} width={128} src="../images/testimoni-3.png" alt="testimoni" className='w-24 m-auto left-6 -top-12 rounded-full z-10' loading='{ eager }'/>
									</div>
									<div className="rating text-left mt-16 ml-5 text-yellow-300">
										<FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" /> <FontAwesomeIcon icon={faStar} size="1x" />
									</div>
									<p className='mt-6 mx-5 text-left'>CS nya ramah dan berpengalaman. Mekanik nya juga tanggap dan komunikatif. Benar-benar memberikan layanan yang baik ke customer. Semoga makin maju <strong>Akimarket</strong>!</p>
									<p className="mt-3 mx-5 text-left">- <b>Erwin</b></p>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
					
				<div className='py-8'>
					<div className='container mx-auto px-8'>
						<div id='produk' className='flex flex-col'>
							<div className='flex justify-center my-10'>
								<h2 className='text-2xl md:text-3xl font-bold text-center'>Produk Kami</h2>
							</div>
						</div>
						<div className='flex flex-col w-full lg:w-4/6 mx-auto'>
							<p className='text-center'><strong>Akimarket</strong> menjual tiga jenis aki yaitu Aki Basah (Conventional Battery), Aki Hybrid dan Aki Kering (Maintenance Free Battery). <strong>Akimarket</strong> menyediakan berbagai macam merek aki kendaraan Anda, seperti GS Astra, Amaron, Incoe, Varta, Bosch, Panasonic, Solite, Faster, Yuasa, Furukawa Battery, dll. <strong>Akimarket</strong> juga menyediakan aki untuk Genset, Forklift dan UPS.</p>
						</div>
						<div className='flex mt-8'>
							<Swiper
							slidesPerView={2}
							spaceBetween={50}
							autoplay={{
								"delay": 2000,
								"disableOnInteraction": true
							}} 
							breakpoints={{
								1024: {
									slidesPerView: 5,
								}
							}}
							>
								<SwiperSlide>
									<StaticImage src="../images/amaron.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/bosch.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/bosch2.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/delkor.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/fb1.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/fb2.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/fb3.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/gs.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/gshybrid.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/gsmf.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/incoe.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/incoegold.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/panasonic.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/panasonic2.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/solite.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/varta.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/yuasa1.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
								<SwiperSlide>
									<StaticImage src="../images/yuasa2.png" alt="produk" loading='{ lazy }'/>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
					
				</div>
				
				<div className='location '>
					<div className='container mx-auto p-8 md:px-10 md:py-10 lg:p-10'>
						<div className='flex flex-col items-center justify-center pb-8'>
							<div className='w-11/12 md:w-1/4 mb-2 flex flex-col justify-center'>
								<div className='flex justify-center mb-6'>
									<h2 className='text-2xl md:text-3xl font-bold text-center text-white'>Hubungi Kami</h2>
								</div>
								<div id='hubungi' className='flex flex-col my-8 bg-white p-12 rounded-lg'>
									<OutboundLink onClick={handleClickCall} href='tel:+6281384758113'>
										<div className='phone text-left text-lg mb-4'>
											<div className="flex flex-row lg:justify-center">
												<div className="flex items-center justify-center mr-4">
													<div className="bottom-call text-white">
														<FontAwesomeIcon icon={faPhone} size="1x" />
													</div>
												</div>
												<div className="text-left">
													<b>Call Now</b><br/> 081384758113
												</div>
											</div>
										</div>
									</OutboundLink>
									<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281384758113&text=Halo%2C akimarket.id" target="_blank">
										<div className='wa text-left text-lg'>
											<div className="flex flex-row lg:justify-center">
												<div className="flex items-center justify-center mr-4">
													<div className="bottom-wa text-white">
														<FontAwesomeIcon icon={faWhatsapp} size="1x" />
													</div>
												</div>
												<div className="text-left">
													<b>Chat Whatsapp</b><br/> 081384758113
												</div>
											</div>
										</div>
									</OutboundLink>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='footer text-center text-sm'>
					<p className='text-gray-200 py-3'>&copy; 2021 <a href="https://www.akimarket.id">Akimarket.id</a> All Right Reserved<br/></p>
				</div>
			</div>
			<OutboundLink onClick={handleClickWhatsapp} href="https://api.whatsapp.com/send?phone=6281384758113&text=Halo%2C akimarket.id" className="wa-float" target="_blank" rel="noreferrer">
				<FontAwesomeIcon className='wa-my-float' icon={faWhatsapp} />
			</OutboundLink>
			<OutboundLink onClick={handleClickCall} href='tel:+6281384758113' className="call-float" rel="noreferrer">
				<FontAwesomeIcon className='call-my-float' icon={faPhone} />
			</OutboundLink>
		</>
  	)
}

export default IndexPage
